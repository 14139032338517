<template>
  <el-dialog
    v-model="dialogFormVisible"
    :title="title"
    width="500px"
    @close="close"
  >
    <el-form ref="formRef" label-width="80px" :model="form" :rules="rules">
      <el-form-item label="排序" prop="sort">
        <el-input v-model="form.sort" />
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
  import {
    defineComponent,
    getCurrentInstance,
    onMounted,
    reactive,
    toRefs,
  } from 'vue'
  import { editBrand, getBrandDetail } from '@/api/brand'

  export default defineComponent({
    name: 'editSort',
    emits: ['fetch-data'],
    setup(props, { emit }) {
      const { proxy } = getCurrentInstance()

      const state = reactive({
        formRef: null,
        treeRef: null,
        checkMenu: [],
        form: {
          sort: 0,
        },
        rules: {
          sort: [{ required: false, trigger: 'blur', message: '请输入排序' }],
        },
        title: '',
        dialogFormVisible: false,
        list: [],
      })

      const showEdit = async (row) => {
        const { data } = await getBrandDetail({
          exhibitorCode: row.exhibitorCode,
        })
        state.form = Object.assign({}, data)
        state.dialogFormVisible = true
      }
      const close = () => {
        state['formRef'].resetFields()
        state.form = {
          sort: 0,
        }
        state.dialogFormVisible = false
      }

      const save = () => {
        state['formRef'].validate(async (valid) => {
          if (valid) {
            let { data } = await editBrand(state.form)
            proxy.$baseMessage(`修改成功`, 'success', 'vab-hey-message-success')
            emit('fetch-data')
            close()
          }
        })
      }
      return {
        ...toRefs(state),
        showEdit,
        close,
        save,
      }
    },
  })
</script>

<style lang="scss" scoped>
  .vab-tree-border {
    height: 200px;
    padding: $base-padding;
    overflow-y: auto;
    border: 1px solid #dcdfe6;
    border-radius: $base-border-radius;
  }
</style>
